<template>
  <div>
    <v-layout row justify-center>
      <v-dialog v-model="loading" persistent fullscreen content-class="loading-dialog" transition="none">
        <v-container fill-height>
        <v-layout row justify-center align-center>
          <v-progress-circular v-if="loading" indeterminate :size="50" color="primary" class="mx-2" style="position:fixed;bottom:50%;left:50%"></v-progress-circular>
        </v-layout>
        </v-container>
      </v-dialog>
    </v-layout>

    <v-container fluid pt-0 grid-list-xl>
			<section-tooltip title="Sales Orders" tooltip="Sales Orders"></section-tooltip>
      <!-- search table -->
      <app-card :heading="$t('message.search')" customClasses="mb-30" >
				<v-form>
          <v-layout row wrap>
            <v-flex sm6>
              <v-text-field label="Term" v-model="filter.code" clearable></v-text-field>
            </v-flex>
            <v-flex sm6>
              <v-text-field label="Date" v-model="filter.date" type="date" clearable></v-text-field>
            </v-flex>
          </v-layout>
					<v-btn @click="search()" color="success"> {{$t("message.search")}} </v-btn>
			    <v-btn @click="newDocument()" color="primary">{{$t("message.create")}}</v-btn>
				</v-form>
			</app-card>

      <!-- search results -->
      <h3 v-if="searchResults.length == 0 && is_searched" style="text-align:center">Sales Order Not Found</h3>
      <app-card :heading="$t('message.searchResults')" customClasses="mb-30" v-if="searchResults.length > 0">
				<v-data-table :headers="searchResultFields" :items="searchResults" item-key="id" select-all :pagination.sync="pagination">
          <template slot="headers" slot-scope="props">
            <tr>
              <th class="text-xs-left fw-bold" v-for="header in props.headers" :key="header.text">
                {{ header.label }}
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr @click="rowClick(props.item)">
              <td>{{ props.item.code }}</td>
              <td>{{ props.item.date==""? '-': convertDate(props.item.date) }}</td>
              <td>{{ props.item.customer_code }}</td>
              <td>{{ props.item.customer_name }}</td>
              <td>{{ props.item.total_sales_amount }}</td>
              <td>{{ props.item.is_draft == 0? 'No': 'Yes'}}</td>
            </tr>
          </template>
        </v-data-table>
			</app-card>

		</v-container>
  </div>
</template>
<script>
import axios from 'axios'
import {api} from '@/components/apiurls'
import {authHeader} from '@/components/global'
import { mapGetters } from "vuex";

export default {
  name: 'sales-quotations',
  components: {
  },
  props: 
  {
    hover: 
    {
      type: Boolean,
      default: false
    },
    striped: 
    {
      type: Boolean,
      default: false
    },
    bordered: 
    {
      type: Boolean,
      default: false
    },
    small: 
    {
      type: Boolean,
      default: false
    },
    fixed: 
    {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      pagination: { rowsPerPage: 25 },
      is_searched: false,
      date:'date',
      inline:'inline',
      select:'select',
      errorMessage:'',
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      successMessage:'',
      modal_header_variant:'primary',
      normal:'normal',
      radios:'radios',
      min_period:'',
      text: 'text',
      modal_title:'',
      msg:'',
      filter_key:'',
      selected:'',
      number:'number',
      searchResults:[],
      searchResultFields:[
        {key: 'code', label: 'SO #',sortable:true},
        {key: 'date' ,label: 'Date',sortable:true},
        {key: 'customer_code',label: 'Customer Code'},
        {key: 'customer_name',label: 'Customer Name'},
        {key: 'total_sales_amount',label: 'Total Sales ($)'},
        {key: 'is_draft',label: 'Is Draft'}
      ],
      loading:false,
      filter:{
        code:'',
        date:''
      }
    }
  },
  async mounted()
  {
    this.filter.code = await this.getSOSearchTerm
    this.searchResults = await this.getSOList

  },
  computed: {
    ...mapGetters([ "getSOSearchTerm", "getSOList" ])
  },
  methods: {
    currencyFormat(num) 
    {
      if (num != null)
      {
        num = (num * 1).toFixed(2)
        return '$' + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    },
    convertDate(date)
    {
      var date = new Date(date)
      date = "" + (date.getDate() > 9 ? '' : '0') + date.getDate() + '/'+  ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + '/' + date.getFullYear()

      return date
    },
    async search() 
    {
      this.loading = true
      this.$store.dispatch("setSOSearchTerm", this.filter.code);

      var sf = {code:this.filter.code, date:this.filter.date, type:'SO'}
      await this.$store.dispatch("searchSo", sf);
      this.searchResults = this.getSOList
      
      for (var i = 0; i < this.searchResults.length; i++)
      {
        if( this.searchResults[i].total_cost_amount == '.00' ) 
        {
          this.searchResults[i].total_cost_amount = '0.00'
        } else 
        this.searchResults[i].total_cost_amount = this.currencyFormat(this.searchResults[i].total_cost_amount)
        
        if ( this.searchResults[i].total_sales_amount == '.00' ) 
        {
          this.searchResults[i].total_sales_amount = '0.00'
          this.searchResults[i].total_sales_amount = this.currencyFormat(this.searchResults[i].total_sales_amount)
        } 
        else 
        {
          this.searchResults[i].total_sales_amount = this.currencyFormat(this.searchResults[i].total_sales_amount)
        } 
        if ( this.searchResults[i].total_margin_amount == '.00' ) 
        {
          this.searchResults[i].total_margin_amount = '0.00'
          this.searchResults[i].total_margin_amount = this.currencyFormat(this.searchResults[i].total_margin_amount)
        } 
        else 
        {
          this.searchResults[i].total_margin_amount = this.currencyFormat(this.searchResults[i].total_margin_amount)
        } 

        // this.searchResults[i].management_margin = '20.00'
        this.searchResults[i].management_margin = this.searchResults[i].markup_percent
        if ( this.searchResults[i].management_margin > this.searchResults[i].total_margin_percent && this.searchResults[i].total_margin_percent != undefined) 
        {
          this.searchResults[i]._rowVariant = 'warning'
        }
      }
      this.is_searched = true
      this.loading = false
    },
    newDocument() 
    {
      this.$router.push({name:'Sales Order', params: { id: '_new'}})
    },
    rowClick(item)
    {
      this.$router.push({name:'Sales Order', params: { id: item.code}})
    },
    hideModal()
    {
      this.$refs.myModalRef.hide()

      if(this.modal_header_variant === 'primary') {
        location.reload()
      }
    },
  }
}
</script>